/// <reference path="DOMAttached.js" />
/// <reference path="Confirm.js" />
/// <reference path="UrlTemplate.js" />

var VimeoPlayer = Class.create(DOMAttached, {
	initialize: function ($super, el, options) {
		$super(el, options);

		this.vimeoPlayer = $(this.options.vimeoIframe);
		this.showSubtitles = this.options.showSubtitles;
		this.autoplay = this.options.autoPlay;
		this.allowSeekForward = this.options.allowSeekForward;
		this.videoProgressKey = 'videoTimeElapsed_' + this.options.videoId + '_' + this.options.memberUUId;
		this.initializeListeners();

		try {
			this.player = new Vimeo.Player(this.vimeoPlayer);
		} catch (err) {
			MiaErrorPrompt(this.options.playerCreationError);
			throw new Error("Can not create player for vimeo video " + this.options.videoId);
		}
		this.setVideoProgress();
		// Handle messages from Vimeo player
		this.player.on('progress', this.onVimeoPlayerLoadProgress.bind(this));
		this.player.on('timeupdate', this.onVimeoPlayerPlayProgress.bind(this));
		this.player.on('play', this.onVimeoPlayerPlay.bind(this));
		this.player.on('pause', this.onVimeoPlayerPause.bind(this));
		this.player.on('ended', this.onVimeoPlayerFinish.bind(this));
		this.player.ready().then(this.onVimeoPlayerReady(this.player));
	},

	/**
	 * Set video progress (if there is any) from the localStorage
	 */
	setVideoProgress: function() {
		var videoProgress = localStorage.getItem(this.videoProgressKey);
		if (videoProgress) {
			this.player.setCurrentTime(videoProgress);
		}
	},

	initializeListeners: function () {
		this.listeners = [];

		if (this.options.rewardUrl != undefined) {
			this.addListener(new RewardVideoListener(this.options.internalVideoId, this.options.rewardTimeSpan,
				this.options.rewardUrl, this.options.rewardAmount, this.options.rewardDescriptionTr));
		}
		if (this.options.fullyWatchedUrl != undefined) {
			this.addListener(new ReportWatchedVideoListener(this.options.fullyWatchedUrl, this.options.duration, this.videoProgressKey));
		}
	},

	addListener: function (listener) {
		this.listeners.push(listener);
	},

	removeListener: function (listener) {
		var index = this.listeners.indexOf(listener);
		if (index != -1) {
			this.listeners.splice(index, 1);
		}
	},

	notifyListenersOnPlayProgress: function (currentSeconds) {
		var index;
		for (index = 0; index < this.listeners.length; index++) {
			this.listeners[index].onPlayProgress(currentSeconds);
		}
	},

	notifyListenersOnLoadProgress: function (percentage) {
		var index;
		for (index = 0; index < this.listeners.length; index++) {
			this.listeners[index].onLoadProgress(percentage);
		}
	},

	notifyListenersOnPlay: function () {
		var index;
		for (index = 0; index < this.listeners.length; index++) {
			this.listeners[index].onPlay();
		}
	},

	notifyListenersOnPause: function () {
		var index;
		for (index = 0; index < this.listeners.length; index++) {
			this.listeners[index].onPause();
		}
	},

	notifyListenersOnEnd: function () {
		var index;
		for (index = 0; index < this.listeners.length; index++) {
			this.listeners[index].onEnd();
		}
	},

	onVimeoPlayerReady: function (player) {
		player.setVolume("1.0");
		if (this.showSubtitles) {
			player.enableTextTrack("en");
		}

		// When disabling autoplay Safari breaks thumbnail of the video. Thus we simply don't autoplay.
		if (this.autoplay && !this.isSafari()) {
			player.play();
		}
	},

	onVimeoPlayerLoadProgress: function (data) {
		this.notifyListenersOnLoadProgress(data.percent * 100.0);
	},

	onVimeoPlayerPlayProgress: function (data) {
		var seconds = data.seconds;

		if (!this.preventSeekForward(seconds)) {
			this.notifyListenersOnPlayProgress(seconds);
		}
	},

	/**
	 * Doesn't allow child to skip part of the video 
	 * @returns true if video can not be forwarded to the given second. false otherwise.
	 */
	preventSeekForward: function(seconds) {
		if (this.allowSeekForward) {
			return false;
		}

		// if timeWatched is null or invalid, then assume that the user is watching for the first time, so timeWatched should be set to 0
		var timeWatched = parseFloat(localStorage.getItem(this.videoProgressKey)) || 0;

		if (seconds < timeWatched) {
			// timeWatched tracks the most forward progress the user has made in a video
			// if seconds is behind timeWatched, then user has rewound, and listeners shouldn't be updated,
			// to prevent 'punishing' users for rewatching lessons.
			return true;
		}

		if (seconds > timeWatched + 4) {
			// timeupdate listeners is called every 250 ms for most browsers (see https://developer.vimeo.com/player/sdk/reference#timeupdate)
			// if the difference between the seconds and the cached timeWatched is greater than that, user has attempted 
			// to seek forward, and the listeners should not be updated and the user should be set back to where they were.
			// Note that we allow for a grace period instead of 250 ms to catch those browsers that have a longer call period.
			this.player.setCurrentTime(timeWatched);
			return true;
		}

		return false;
	},

	onVimeoPlayerPlay: function () {
		this.notifyListenersOnPlay();
	},

	onVimeoPlayerPause: function () {
		this.notifyListenersOnPause();
	},

	onVimeoPlayerFinish: function () {
		this.notifyListenersOnEnd();
		// This is needed to reload the player (#11862)
		// Vimeo API method unload() isn't supported in HTML players, so we need to reset the url in the iFrame.
		// See https://stackoverflow.com/a/19719340
		this.player.element.src = this.player.element.src;
	},

	getDurationSeconds: function () {
		return this.options.duration;
	},

	isSafari: function () {
		var userAgent = navigator.userAgent.toLowerCase();
		return userAgent.indexOf("safari") !== -1 && userAgent.indexOf("chrome") == -1;
	},
});
