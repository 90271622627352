/// <reference path="../TextToSpeech.js" />

var GamesTextToSpeechInstance = null;

/**
 * Subclass of TextToSpeech that handles what is common to 
 * all games that use text to speech: Set the speech sounds, 
 * play question (and answers), highlight elements while their
 * text is being read, create hover effects that allow replaying
 * the texts etc.
 */
var GamesTextToSpeech = Class.create(TextToSpeech, {
	initialize: function ($super, game, isEnabled, isEnabledByParent) {
		$super();
		GamesTextToSpeechInstance = this;

		this.game = game;
		this.textToSpeechElements = [];
		this.isEnabled = isEnabled;
		this.isEnabledByParent = isEnabledByParent;
	},

	/**
	 * Use this to configure which elements should currently be read
	 * for the corresponding game. textToSpeechElements is a list of 
	 * objects with the following properties:
	 * - domElement (will be highlighted while the sound is playing), 
	 * - identifier (for accessing the sound), 
	 * - soundUrl (of the sound to be played), 
	 * - defaultColor (used when the sound is currently not playing), 
	 * - highlightColor (used as background color while the sound is playing).
	 */
	setTextToSpeechElements: function (textToSpeechElements) {
		this.textToSpeechElements = textToSpeechElements;
		this.setSounds(this.getIdentifierToSoundUrlDictioary(textToSpeechElements));
		
		if(this.isEnabledByParent) {
			this.showPlayButtons();
		} else {
			this.hidePlayButtons();
		}
		
		this.initializePlayButtonClickHandlers();		
	},
	
	/**
	 * Call this when displaying the question and its answers. It reads
	 * all text to speech elements in their order.
	 */
	readSpeechForAllElementsInOrder: function () {
		if (!this.isEnabled) {
			return;
		}
		this.readSpeechForElementsInOrder(this.textToSpeechElements);
	},

	/**
	 * Removes all elements' highlighting effects and stops all the sounds that might be 
	 * playing. Call this e.g. when text to speech gets turned off.
	 */
	removeTextToSpeechEffects: function () {
		this.stopAllExistingSounds();

		for (var i = 0; i < this.textToSpeechElements.length; i++) {
			var currentElement = this.textToSpeechElements[i];
			this.stopHighlighting(currentElement);
		}
	},

	/**
	 * Call this to turn on text to speech.
	 */
	turnOnTextToSpeech: function () {
		this.isEnabled = true;
	},

	/**
	 * Call this to turn off text to speech and stop reading any
	 * speech that might currently be playing.
	 */
	turnOffTextToSpeech: function () {
		this.isEnabled = false;
		this.removeTextToSpeechEffects();
	},

	/**
	 * Private, don't call.
	 */
	stopHighlighting: function (element) {
		this.setBoxShadow(element.domElement, element.defaultFontColor);
	},

	/**
	 * Private, don't call.
	 * Recursive function for reading all given text to speech elements.
	 */
	readSpeechForElementsInOrder: function (textToSpeechElements) {
		var _this = this;

		var currentElement = textToSpeechElements[0];
		this.setBoxShadow(currentElement.domElement, currentElement.highlightFontColor);

		var onFinishedPlaying = function () {
			_this.setBoxShadow(currentElement.domElement, currentElement.defaultFontColor);
			if (textToSpeechElements.length != 1) {
				_this.readSpeechForElementsInOrder(textToSpeechElements.slice(1));
			}
		};

		this.playSpeechForTextIdentifier(currentElement.identifier, onFinishedPlaying);
	},

	/**
	 * Private, don't call.
	 * Extracts the (identifier -> sound url) mapping from the given 
	 * text to speech elements.
	 */
	getIdentifierToSoundUrlDictioary: function (textToSpeechElements) {
		var identifierToSoundUrlDictioary = {};
		for (var i = 0; i < textToSpeechElements.length; i++) {
			var currentElement = textToSpeechElements[i];
			identifierToSoundUrlDictioary[currentElement.identifier] = currentElement.soundUrl;
		}
		return identifierToSoundUrlDictioary;
	},

	/**
	 * Private, don't call.
	 * You have to click the button to play the corresponding speech.
	 */
	initializePlayButtonClickHandlers: function () {
		for (var i = 0; i < this.textToSpeechElements.length; i++) {
			this.initializePlayButtonClickHandler(this.textToSpeechElements[i]);
		}
	},

	/**
	 * Private, don't call.
	 * Helper method for initializing all play button click handlers.
	 */
	initializePlayButtonClickHandler: function (textToSpeechElement) {
		var _this = this;
		var button = textToSpeechElement.domElement.down('.mia-ttsButton');
		this.game.stopObserveAllListeners(button);
		this.game.safeObserve(button, 'click', function (ev) {
			ev.stop();
			// Set default color for all answers before highlighting the selected one
			for (var i = 0; i < _this.textToSpeechElements.length; i++) {
				_this.setBoxShadow(_this.textToSpeechElements[i].domElement, _this.textToSpeechElements[i].defaultFontColor);
			}
			_this.setBoxShadow(textToSpeechElement.domElement, textToSpeechElement.highlightFontColor);
			_this.playSpeechForTextIdentifier(textToSpeechElement.identifier, function () {
				_this.setBoxShadow(textToSpeechElement.domElement, textToSpeechElement.defaultFontColor);
			});
		});
	},
	
	/**
	 * Private, don't call.
	 * Sets the given domElement's font color to the given color.
	*/
	setBoxShadow: function (domElement, color) {
		var domObject = $(domElement);
		if (domObject != null) {
			domObject.setStyle({ boxShadow: '0px 0px 8px 0px ' + color });
		}
	},

	/**
	 * Private, don't call.
	 * The buttons are used for Android devices to play an element's speech.
	 */
	hidePlayButtons: function () {
		for (i = 0; i < this.textToSpeechElements.length; i++) {
			var button = this.textToSpeechElements[i].domElement.down('.mia-ttsButton');
			if (button != undefined && button != null) {
				this.game.stopObserve(button, 'click');
				button.setStyle({ display: 'none' });
			}
		}
	},

	/**
	 * Private, don't call.
	 * The buttons are used to play an element's speech.
	 */
	showPlayButtons: function () {
		for (i = 0; i < this.textToSpeechElements.length ; i++) {
			var button = this.textToSpeechElements[i].domElement.down('.mia-ttsButton');
			if (button != undefined && button != null) {
				button.setStyle({ display: 'inline-block' });
			}
		}
	},
});
